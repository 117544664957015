import { heading3Style, smallStyle } from 'components/Common/Text';
import { css } from 'styled-components';
import {
  IngredientsButton,
  TitleContainer,
} from 'components/Product/BundleAddToCartBlock/BundleAddToCartBlock.styles';
import { LadderThemeProp } from 'theme';
import { VariantOptionSelectorTitle } from 'components/Product/VariantOptionSelector/VariantOptionSelector.styles';
import {
  Headline,
  SectionItemText,
} from 'components/Product/BundleAddToCartBlock/ProductDescription.styles';
import { fontSize } from 'styled-utils';

export const LadderBundleAddToCartBlock = css`
  ${TitleContainer} {
    h1 {
      ${heading3Style}
    }
  }

  ${VariantOptionSelectorTitle} {
    ${smallStyle};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  ${Headline} {
    ${({ theme }) =>
      fontSize({
        fontSize: [theme.fontSizes.small, '16px'],
      })};
  }

  ${SectionItemText} {
    p,
    ul {
      margin: 0;
    }
  }

  ${IngredientsButton} {
    background-color: ${({ theme }) => theme.colors.basic100};
    border-radius: 35px;
    border: 1px solid ${({ theme }) => theme.colors.basic400};
    color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
  }
`;
