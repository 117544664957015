import { bodyStyle } from 'components/Common/Text';
import { BundleItemDescription } from 'components/Product/BundleItemList/BundleItemList.styles';
import { css } from 'styled-components';

export const LadderBundleItemList = css`
  ${BundleItemDescription} {
    h2 {
      ${bodyStyle}
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      text-transform: none;
    }
  }
`;
