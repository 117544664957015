import styled from 'styled-components';
import chevronDownSVG from 'svg/chevron-down.svg';
import comparisonCheck from 'svg/comparison-check.svg';
import comparisonX from 'svg/comparison-x.svg';
import { margin, space } from 'styled-utils';
import { Select } from './Select';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { heading2Style } from 'components/Common/Text';

type BackgroundColor = 'dark' | 'light';

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.basic200};
  ${space({ pb: ['32px', '40px'], pt: ['32px', '8px'] })};
`;

export const Title = styled.div`
  ${space({ padding: ['0 40px', '0'] })};
  text-align: center;
  ${heading2Style};
  ${margin({ marginBottom: [16, 25] })}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.basic400};
  padding: 18px 0 0;
  background-color: ${({ theme }) => theme.colors.basic100};
  max-width: 1240px;
  width: 100%;
`;

export const MobileChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.basic400};
  min-width: 328px;
  max-width: 360px;
  background-color: ${({ theme }) => theme.colors.basic100};
  ${space({ mx: [15, 0] })};
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.colors.basic100};
  padding: 0 5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${space({ padding: ['0 8px 0 0', 0] })};
  width: 100%;
`;

export const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 172px;
  padding: 0 8px 0 0;
  width: 100%;
`;

export const PrimaryBrandMobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export const Cell = styled.div<{
  backgroundColor?: BackgroundColor;
  primaryBrand?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === 'dark' ? theme.colors.basic200 : theme.colors.basic100};
  height: 48px;
  border-left: ${({ primaryBrand, theme }) =>
    primaryBrand ? 'none' : `1px solid ${theme.colors.basic400}`};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme }) => theme.colors.basic800};
`;

export const MobileColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobilePrimaryBrandColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 179px;
  width: 100%;
`;

export const RowHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 106px;
  max-width: 164px;
  width: 100%;
`;

export const RowHeader = styled.div<{ backgroundColor?: BackgroundColor }>`
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === 'dark' ? theme.colors.basic200 : theme.colors.basic100};
  height: 48px;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  padding: 0 11px;
  line-height: 16px;
`;

export const MobileRowHeader = styled.div<{
  backgroundColor?: BackgroundColor;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === 'dark' ? theme.colors.basic200 : theme.colors.basic100};
  height: 48px;
  padding: 0 0 0 8px;
  margin: 0 0 0 8px;
`;

export const MobileHeaderText = styled.div`
  display: flex;
  flex: 0.5;
  align-items: center;
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  line-height: 16px;
`;

export const Corner = styled.div`
  height: 37px;
`;

export const DisclaimerContainer = styled.div`
  padding: 0 1em;
  width: 100%;
`;

export const ComparisonCheck = styled.div`
  flex: 0.5;
  background-image: url(${comparisonCheck.url});
  background-size: cover;
  height: 12px;
`;

export const ComparisonX = styled.div`
  flex: 0.5;
  background-image: url(${comparisonX.url});
  background-size: cover;
  height: 12px;
  width: 12px;
`;

export const MobileColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.basic100};
  height: 55px;
  padding: 15px 8px 12px 16px;
`;

export const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 7px;
  height: 37px;
  border-left: 1px solid ${({ theme }) => theme.colors.basic400};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  color: ${({ theme }) => theme.colors.basic800};
  line-height: 16px;
`;

export const PrimaryBrandColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  border-left: 1px solid ${({ theme }) => theme.colors.basic400};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  color: ${({ theme }) => theme.colors.basic800};
  line-height: 16px;
`;

export const PrimaryBrandLogo = styled(GatsbyImage)`
  width: 100%;
  ${space({
    mx: ['0', '3px', '16px'],
  })}
`;

export const MobileLogo = styled(PrimaryBrandLogo)`
  flex: 1;
`;

export const ComparisonSelection = styled(Select)`
  flex: 1;
  color: ${({ theme }) => theme.colors.basic800};
`;

export const StyledFieldSelect = styled.select`
  border: 1px solid ${({ theme }) => theme.colors.basic400};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.default};
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  box-shadow: none;
  background-color: ${({ theme }) => theme.colors.basic100};
  background-image: url(${chevronDownSVG.url});
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 7px;
  transition: border-color 0.3s;
  &:focus {
    border-color: ${({ theme }) => theme.colors.default};
  }
  padding: 0 9px 0 7px;
  height: 33px;
  width: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
