import { BottomItemSubtitle } from 'components/Product/ProductAbout/ProductAbout.styles';
import { heading3Style, smallStyle } from 'components/Common/Text';
import {
  BottomInfoText,
  BottomItemTitle,
} from 'components/Product/ProductAbout/ProductAbout.styles';
import { css } from 'styled-components';

export const LadderProductAbout = css`
  ${BottomInfoText} {
    p {
      margin: 0;
    }
  }
  ${BottomItemTitle} {
    ${heading3Style}
  }
  ${BottomItemSubtitle} {
    ${smallStyle};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;
