import styled from 'styled-components';

export const SupplementSelectorGridContainer = styled.div`
  margin-bottom: 1rem;
`;

export const SelectorGridTitle = styled.div<{ hasBottomMargin?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  ${({ hasBottomMargin = true }) =>
    hasBottomMargin ? '  margin-bottom: 8px;' : ''};
`;

export const SelectorGridTop = styled.div`
  display: grid;
  margin-bottom: 1rem;
`;

export const ServingTypeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:not(:last-child) {
    margin-right: 8px;
  }
`;

export const OptionsContainer = styled.div`
  display: grid;
  margin-bottom: 1rem;
`;
