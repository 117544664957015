import styled, { createGlobalStyle } from 'styled-components';
import { fontSize, padding } from 'styled-utils';
import { StyledModalClose } from 'components/Modal';
import { StyledButton } from 'components/Common/Button/styles';
import { StyledFieldCheckbox } from 'components/Common/Checkbox/Checkbox.styles';
import { LadderProductRatingTitle } from './styles/LadderProductRating.styled';
import { device, LadderThemeProp } from 'theme';
import { SeeAllReviewsContainer } from 'components/Product/ReviewSection/ReviewSection.styles';
import {
  FAQLinkContainer,
  QuestionAnswer,
} from 'components/Product/ProductFAQ/ProductFAQ.styles';
import { BottomInfoText } from 'components/Product/ProductAbout/ProductAbout.styles';
import { IngredientsButtonWrapper } from 'components/Product/BundleItemList/BundleItemList.styles';
import {
  LadderIconList,
  LadderSquareImageSlider,
  LadderBundleItemList,
  LadderAddToCartBlock,
  LadderTypographyStyles,
  LadderProductSlider,
} from './styles';

import { bodyStyle, smallStyle } from 'components/Common/Text';
import { LadderProductFAQ } from './styles/LadderProductFAQ.styled';
import { LadderProductAbout } from './styles/LadderProductAbout.styled';
import { LadderBundleAddToCartBlock } from './styles/LadderBundleAddToCartBlock.styled';
import { IngredientsButton } from 'components/Product/AddToCartBlock/AddToCartBlock.styles';
import {
  ButtonArrow,
  CtaButton as SquareImageSliderButton,
} from 'components/SquareImageSlider/SquareImageSlider.styles';
import { LadderComparisonChart } from './styles/LadderComparisonChart.styled';

export const LadderGlobalStyle = createGlobalStyle`
  font-family: ${({ theme }) => theme.fonts.default};
  html {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }

  body {
    ${bodyStyle};
    color: black;
    ${padding({ pb: [60, 60, 0] })}
  }
`;

/* LadderTypographyStyles needs to come first, so others can override it. */
export const LadderStyle = styled.div`
  ${LadderTypographyStyles};

  ${StyledButton} {
    border-width: 0;
    background-color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
    border-radius: 2px;
    ${({ theme }) => fontSize({ fontSize: theme.typography.default.fontSize })};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    &[disabled] {
      background-color: ${({ theme }) => theme.colors.basic400};
    }
    color: ${({ theme }) => theme.colors.basic100};
    &:hover {
      background-color: ${({ theme }: LadderThemeProp) => theme.colors.blue500};
      color: ${({ theme }) => theme.colors.basic100};
    }
  }
  a {
    &:hover {
      color: ${({ theme }: LadderThemeProp) => theme.colors.blue500};
    }
  }

  ${IngredientsButton} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.basic100};
      color: ${({ theme }: LadderThemeProp) => theme.colors.blue500};
    }
  }

  ${SquareImageSliderButton} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.basic100};
      color: ${({ theme }: LadderThemeProp) => theme.colors.blue500};
      ${ButtonArrow} {
        background-color: ${({ theme }: LadderThemeProp) =>
          theme.colors.blue500};
      }
    }
  }

  ${LadderComparisonChart};

  ${LadderBundleItemList};

  ${LadderProductRatingTitle};

  ${LadderSquareImageSlider};

  ${LadderAddToCartBlock};

  ${LadderIconList};

  ${LadderProductSlider};

  ${LadderProductFAQ};

  ${LadderProductAbout};

  ${LadderBundleAddToCartBlock};

  ${/* sc-selector */ BottomInfoText},
  ${/* sc-selector */ IngredientsButtonWrapper},
  ${/* sc-selector */ FAQLinkContainer},
  ${/* sc-selector */ QuestionAnswer},
  ${/* sc-selector */ SeeAllReviewsContainer} {
    a {
      ${smallStyle};
      color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
      :after {
        content: '>';
        width: unset;
        height: unset;
        right: -0.75em;
        top: 50%;
        transform: translateY(-50%);
        /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
        mask-image: unset;
        background-color: unset;
      }
      &:hover {
        color: ${({ theme }: LadderThemeProp) => theme.colors.blue500};
      }
    }
  }

  ${StyledModalClose} {
    border: none;
    right: 1.5rem;
    @media (min-width: ${device.large.width}) {
      top: 3rem;
      right: 3rem;
    }

    &:before,
    &:after {
      border-width: 2px;
      width: 2.5rem;
      border-color: black;
    }
  }

  ${StyledFieldCheckbox} {
    &:checked + label {
      &:before {
        border-color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
        background-color: ${({ theme }: LadderThemeProp) =>
          theme.colors.blue700};
        color: ${({ theme }) => theme.colors.basic100};
      }
    }
  }
`;

export const LadderBlock = styled.div`
  max-width: 1340px;
  margin: auto;
`;
