enum SiteType {
  openfit = 'openfit',
  ladder = 'ladder',
}

export const currentSite = (): SiteType => {
  if (typeof window === 'undefined') {
    return SiteType.openfit;
  }

  return window.location.hostname.includes(SiteType.ladder)
    ? SiteType.ladder
    : SiteType.openfit;
};
