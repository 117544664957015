import styled from 'styled-components';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { layout, space, display } from 'styled-utils';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  ${space({ mb: ['50px', 0] })}
`;

export const Selector = styled.div`
  ${display({ display: ['none', 'flex'] })}
  flex-direction: column;
  flex: 0 1 72px;
  margin-right: 16px;
`;

export const ProductImageHolder = styled.div`
  flex: 1;
  flex-basis: 512px;
`;

export const SelectorItemWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  margin-bottom: 16px;
`;

export const SelectorItem = styled.button`
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.basic200};
  &:focus {
    outline: 0;
  }
`;

export const SelectorItemActive = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid ${({ theme }) => theme.colors.blue400};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const SelectorItemImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

export const WrapperMobile = styled.div`
  .keen-slider {
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;

    &__slide {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      min-height: 100%;

      /* Prevent tooltip cutoff */
      :hover {
        overflow: visible;
      }
    }

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }
  }
`;

export const SliderMobile = styled.div`
  height: 100vw;
  width: 100%;
`;

export const DotsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

export const Dot = styled.button<{ isActive?: boolean }>`
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: 0;
  }
  background: ${({ isActive, theme }): string =>
    isActive ? theme.colors.blue400 : theme.colors.basic400};
  border-radius: 50%;
  ${layout({ size: ['8px'] })};
  ${space({ mx: ['4px'] })};
`;
