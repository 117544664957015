import { Button } from 'components/Common/Button';
import styled from 'styled-components';
import {
  display,
  width,
  fontSize,
  space,
  margin,
  typography,
} from 'styled-utils';

export const Container = styled.div`
  position: relative;
  ${display({ display: ['block', 'flex'] })};
`;

export const ProductImageContainer = styled.div`
  ${width({ width: ['100%', '50%'] })};
  ${space({ paddingTop: [10, 0] })};
`;

export const TitleContainer = styled.div`
  ${space({ p: ['0 15px', '0 0', '0 0'] })};
  ${fontSize({ fontSize: ['20px', '32px'] })};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${margin({ marginBottom: [0, 32], marginTop: [36, 0] })};
`;

export const BuyBoxContainer = styled.div`
  ${width({ width: ['auto', '50%'] })};
  ${space({ p: ['12px 15px', '12px 15px', '0 40px'] })};
`;

export const IngredientsButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 1rem auto auto;
`;

export const Rating = styled.a`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  display: flex;
  align-items: center;
  text-decoration: none;

  ${space({ mt: ['4px', '8px'] })};
`;

export const RatingText = styled.p`
  display: inline-block;
  ${({ theme }) =>
    fontSize({
      fontSize: [theme.fontSizes.xSmall, theme.fontSizes.small],
    })};
  color: ${({ theme }) => theme.colors.basic600};
  margin: 0 0 0 5px;
  padding: 0;
`;

export const IngredientsModalContent = styled.div`
  ${space({ p: ['60px 15px', '60px 15px', '43px 125px'] })};
  width: 90vw;
  max-width: 792px;
`;

export const LocaleModalContent = styled.div`
  ${space({ p: ['48px 24px', '48px 24px', '64px 80px'] })};
  width: 90vw;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    ${({ theme }) => typography(theme.typography.heading3)};
    margin-bottom: 16px;
  }

  p {
    ${({ theme }) => typography(theme.typography.default)};
    margin-bottom: 40px;
  }
`;
