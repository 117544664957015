import React from 'react';
export type SvgProps = {
  icon: { viewBox?: string | undefined; url: string };
  useFontSize?: boolean;
  className?: string;
  width?: string;
  height?: string;
};

export const Svg: React.FC<SvgProps> = ({
  icon: { viewBox, url },
  useFontSize = false,
  className,
  height,
  width,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={viewBox}
      // Setting width and height like this allows SVG to be sized with font-size
      style={useFontSize ? { width: '1em', height: '1em' } : undefined}
      // preact transpile xLinkHref prop to href
      // which is not supported on Safari 11
      dangerouslySetInnerHTML={{
        __html: `<use xlink:href="${url}"></use>`,
      }}
    ></svg>
  );
};
