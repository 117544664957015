import { SelectOptionProps } from 'components/Common/Select/Select';
import React, { FormEvent, FunctionComponent } from 'react';
import { StyledFieldSelect } from './ComparisonChart.styles';

interface SelectProps extends React.OptionHTMLAttributes<HTMLSelectElement> {
  items: SelectOptionProps[];
  changeHandler: (value: string) => void;
}

export const Select: FunctionComponent<SelectProps> = ({
  items,
  value,
  changeHandler,
}) => {
  const handleOnChange = (e: FormEvent<HTMLSelectElement>) => {
    changeHandler(e.currentTarget.value);
  };

  return (
    <StyledFieldSelect onChange={handleOnChange} value={value}>
      {items.map(({ id, text, ...optionProps }, index) => (
        <option key={id || index} value={text} {...optionProps}>
          {text}
        </option>
      ))}
    </StyledFieldSelect>
  );
};
