import styled from 'styled-components';
import { device } from 'theme';
import checkmark from 'svg/checkmark.svg';

export const StyledFieldWrap = styled.div`
  overflow: hidden;
`;

export const StyledFieldCheckbox = styled.input<{
  square?: boolean;
}>`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;

  &:checked + label {
    &:before {
      border-color: ${({ theme }) => theme.colors.blue400};
      background: ${({ theme }) => theme.colors.blue400};
      background-color: ${({ theme }) => theme.colors.blue400};
      background-image: url(${checkmark.url});
      background-size: ${({ theme }) => theme.fontSizes.small};
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const StyledFieldCheckboxLabel = styled.label<{
  square?: boolean;
}>`
  padding-left: ${(props) => (props.square ? '27px' : '30px')};
  padding-right: 8px;
  position: relative;
  font-size: 12px;
  line-height: 1.33;
  cursor: pointer;
  display: flex;
  min-height: ${(props) => (props.square ? '22px' : '32px')};
  align-items: center;
  user-select: none;

  @media (min-width: ${device.small.width}) {
    line-height: 1.5;
  }

  &:before {
    position: absolute;
    left: 4px;
    top: ${(props) => (props.square ? '0' : '4px')};
    content: '';
    background-color: ${({ theme }) => theme.colors.basic200};
    width: ${(props) => (props.square ? '20px' : '25px')};
    height: ${(props) => (props.square ? '20px' : '25px')};
    border-radius: ${(props) => (props.square ? '4px' : '50%')};
    border: 1px solid
      ${({ square, theme }) =>
        square ? theme.colors.basic400 : theme.colors.default};
  }

  div {
    display: flex;
    flex-direction: column;
    span:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  a {
    color: currentColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const GrayCheckboxModifier = styled.div`
  ${StyledFieldCheckboxLabel} {
    padding-left: 35px;
    &:before {
      background-color: ${({ theme }) => theme.colors.basic100};
      border-color: ${({ theme }) => theme.colors.basic400};
    }
  }
`;
