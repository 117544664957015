import { heading3Style, smallStyle, xSmallStyle } from 'components/Common/Text';
import { css } from 'styled-components';
import {
  IngredientsButton,
  RatingText,
  TitleContainer,
} from 'components/Product/AddToCartBlock/AddToCartBlock.styles';
import { LadderThemeProp } from 'theme';
import { SelectorGridTitle } from 'components/Product/SupplementSelectorGrid/SupplementSelectorGrid.styles';
import { VariantGroupTitle } from 'components/Product/VariantGroupSelector/VariantGroupSelector.styles';
import { SwatchTitle } from 'components/Product/SubscribeAndSave/SubscribeAndSave.styles';
import { SelectorItemActive } from 'components/Product/ProductImageMultiple/ProductImageMultiple.styles';

export const LadderAddToCartBlock = css`
  ${TitleContainer} {
    h1 {
      ${heading3Style}
    }
  }

  ${SelectorGridTitle},
  ${SwatchTitle},
  ${VariantGroupTitle} {
    ${smallStyle}
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  ${RatingText} {
    ${xSmallStyle}
    text-transform: none;
  }

  ${IngredientsButton} {
    background-color: ${({ theme }) => theme.colors.basic100};
    border-radius: 35px;
    border: 1px solid ${({ theme }) => theme.colors.basic400};
    color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
  }

  ${SelectorItemActive} {
    border: 2px solid ${({ theme }) => theme.colors.black};
  }
`;
