import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import type { AmplitudeClient } from 'amplitude-js';
import { Maybe } from 'graphql-types';
import { navigateToUrl } from './General';
import { currentSite } from 'utils/site';
import {
  isMobile,
  amplitudeLog,
  amplitudeView,
} from '@bbnb/openfit-frontend-shared';

declare global {
  interface Window {
    amplitude?: {
      getInstance: () => AmplitudeClient & {
        _isInitialized: boolean;
        onInit: (cb: () => void) => void;
      };
    };
  }
}

type MaybeArray<T> = Maybe<T>[];

type AmplitudeData = Record<
  string,
  | Maybe<string>
  | MaybeArray<string>
  | Maybe<number>
  | MaybeArray<number>
  | MaybeArray<unknown>
>;

const TIMEOUT = 10;
const MAX_ATTEMPTS = 100;

export function setUserProperties(
  userProperties: Record<string, any> = {}
): Promise<void> {
  let dataSent = false;
  function sendData() {
    if (!dataSent) {
      window.parent.amplitude?.getInstance().setUserProperties(userProperties);
    }

    dataSent = true;
  }
  function sendToAmplitude(callId: number, cb: () => void) {
    if (callId >= MAX_ATTEMPTS) {
      cb();
      return;
    }

    const instance = window.parent.amplitude?.getInstance();
    if (instance) {
      if (instance._isInitialized) {
        sendData();
        cb();
      } else if (instance.onInit) {
        instance.onInit(() => {
          sendData();
          cb();
        });
      }
    } else {
      setTimeout(() => {
        sendToAmplitude(callId + 1, cb);
      }, TIMEOUT);
    }
  }

  return new Promise((resolve) => {
    sendToAmplitude(0, resolve);
  });
}

export function useLogPageViewed(eventName = 'Page Viewed', data = {}) {
  useEffect(() => {
    logEvent(eventName, {
      url_path: window.location.pathname,
      ...data,
    });
  }, [eventName, data]);
}

export const logEvent = (
  eventName: string,
  data: AmplitudeData = {}
): Promise<any> =>
  amplitudeLog(eventName, {
    site: currentSite(),
    ...data,
    device: isMobile ? 'mobile' : 'desktop',
  });

export const awaitClick = (
  callback: (event: React.MouseEvent<HTMLAnchorElement>) => Promise<unknown>
) => async (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.persist();
  event.preventDefault();
  const href = event.currentTarget.getAttribute('href');
  if (callback) {
    await callback(event);
  }

  href && navigateToUrl(href);
};

export const awaitMouseClick = (
  callback: (event: MouseEvent) => Promise<unknown>
) => async (event: MouseEvent) => {
  event.preventDefault();
  const href = (event.target as HTMLAnchorElement).getAttribute('href');
  if (callback) {
    await callback(event);
  }

  href && navigateToUrl(href);
};

export function logView({
  programId = '',
  marketing_type = '',
}: {
  programId?: string;
  marketing_type?: string;
}): void {
  amplitudeView('Shop Page Viewed', { programId, marketing_type });
}

export const getProgramId = () => Cookies.get('program_code') || '';

// Returns a mapping of the URLSearchParams with keys being lowercase
export const mapAndFormatSearchParams = (
  params: URLSearchParams
): Map<string, string> =>
  new Map([...params].map(([k, v]) => [k.toLowerCase(), v]));
