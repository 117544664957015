import styled from 'styled-components';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { layout } from 'styled-utils';

export const ImageWrapper = styled.div`
  margin-bottom: 12px;
  width: 100%;
  ${layout({ height: [130, 152] })};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const ImagePositionWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const SquareImageWrapper = styled.div`
  ${ImageWrapper} {
    width: 100%;
    margin: 0;
    height: auto;
    position: relative;
    /* 1:1 Aspect Ratio */
    padding: 100% 10px 0 10px;
  }

  ${ImagePositionWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    color: white;
  }
`;

export const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.basic200};
`;
