import { css } from 'styled-components';
import { width } from 'styled-utils';
import {
  IconContainer,
  TitleContainer,
  Info,
  InfoText,
} from 'components/Common/IconsList/styles';

export const LadderIconList = css`
  ${TitleContainer} {
    display: none;
  }

  ${IconContainer} {
    ${width({ width: ['33%', '33%', '11%'] })};
    img {
      width: 100%;
    }
  }

  ${Info} {
    color: ${({ theme }) => theme.colors.basic600};
    margin: 10px auto 0;
    padding: 0;
    width: 100%;
  }

  ${InfoText} {
    font-size: 10px;
    line-height: 1.33;
    text-transform: uppercase;
  }
`;
