import styled from 'styled-components';
import {
  color,
  space,
  width,
  fontSize,
  maxWidth,
  position,
  borderColor,
  layout,
} from 'styled-utils';
import { LadderThemeProp } from 'theme';

export const defaultCloseButtonPlacement = {
  top: '20px',
  right: '9px',
};

const DEFAULT_OVERLAY_OPACITY = 0.75;

interface CloseButtonProps {
  showBtnCloseMobile?: boolean;
  showBtnCloseDesktop?: boolean;
  showBtnClose?: boolean;
}

export const Overlay = styled.div<{
  backgroundColor?: string;
  opacity?: number;
}>`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: 100;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background-color: ${({ backgroundColor, theme }) =>
      backgroundColor ? backgroundColor : theme.colors.black};
    opacity: ${({ opacity }) => (opacity ? opacity : DEFAULT_OVERLAY_OPACITY)};
    z-index: -1;
  }
`;

export const StyledModalClose = styled.div<CloseButtonProps>`
  background-color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.basic400};
  width: 36px;
  height: 36px;
  position: absolute;
  top: ${defaultCloseButtonPlacement.top};
  right: ${defaultCloseButtonPlacement.right};
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }

  &:before,
  &:after {
    content: '';
    width: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.basic100};
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  ${({ showBtnCloseMobile, showBtnCloseDesktop, showBtnClose }) => {
    const mobile = showBtnCloseMobile || showBtnClose ? 'block' : 'none';
    const desktop = showBtnCloseDesktop || showBtnClose ? 'block' : 'none';
    return layout({ display: [mobile, mobile, desktop] });
  }};
`;

export const ModalStyled = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentRelative = styled.div`
  position: relative;
`;

export const InnerContent = styled.div<{ borderRadius?: string }>`
  position: relative;
  display: block;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
  overflow: hidden;
  text-align: center;
  padding: 0;
  ${({ theme }) =>
    color({
      bg: [theme.colors.basic100, theme.colors.basic100, theme.colors.basic200],
    })};
`;

export const FullSizeModal = styled.div`
  ${ModalStyled} {
    height: 100%;
  }

  ${ContentRelative} {
    width: 100%;
    height: 100%;
  }

  ${InnerContent} {
    height: 100%;
    padding-bottom: 30px;
    overflow-y: auto;
    border-radius: 0;
    background: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
  }

  ${StyledModalClose} {
    background-color: transparent;

    &:before,
    &:after {
      border-bottom-color: ${({ theme }) => theme.colors.basic100} !important;
    }
  }
`;

export const TextWithoutHeader = styled.div`
  margin: 45px 35px 40px;

  ${maxWidth({ maxWidth: ['250px', '250px', '310px'] })};
  ${space({ m: ['45px 35px 40px', '45px 35px 40px', '120px 100px'] })};

  button {
    max-width: 242px;
    line-height: 1.13;
    background: ${({ theme }) => theme.colors.basic400};
    ${space({ py: [15, 15, 19] })};
    ${fontSize({ fontSize: [14, 14, 16] })};
  }

  p {
    margin-bottom: 40px;

    ${fontSize({ fontSize: [16, 16, 18] })};
    ${space({ mb: [40, 40, 50] })};
  }
`;

export const RedesignModalView = styled.div`
  ${InnerContent} {
    border-radius: 10px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  }

  ${StyledModalClose} {
    border: none;
    background-color: transparent;

    ${position({
      top: [
        defaultCloseButtonPlacement.top,
        defaultCloseButtonPlacement.top,
        '-65px',
      ],
    })};

    &:before,
    &:after {
      border-width: 3px;
      ${width({ width: ['18px', '18px', '27px'] })};
      ${({ theme }) =>
        borderColor({
          borderColor: [
            theme.colors.basic800,
            theme.colors.basic800,
            theme.colors.basic100,
          ],
        })};
    }
  }
`;
