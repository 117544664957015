import styled, { css } from 'styled-components';
import { fontSize, lineHeight, size, textAlign } from 'styled-utils';
import {
  screenAwareColor,
  ScreenAwareColorProps,
} from 'components/Common/Placement/Placement';
import arrow from 'svg/arrow-right.svg';

export const CommonTitle = styled.div<ScreenAwareColorProps>`
  line-height: 36px;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${fontSize({ fontSize: ['26px', '26px', '32px'] })};
  ${textAlign({ textAlign: ['center', 'center', 'left'] })};
  ${({ colorMobile, colorDesktop, theme }) =>
    screenAwareColor(
      colorMobile || theme.colors.basic100,
      colorDesktop || theme.colors.basic100
    )};
`;

export const CommonDescription = styled.div<ScreenAwareColorProps>`
  ${fontSize({ fontSize: ['14px', '14px', '18px'] })};
  ${textAlign({ textAlign: ['center', 'center', 'left'] })};
  ${lineHeight({ lineHeight: ['20px', '20px', '24px'] })};
  color: ${({ theme }) => theme.colors.basic100};
`;

export const ArrowStyle = css`
  text-decoration: none;
  position: relative;
  :after {
    content: ' ';
    ${size({ size: `1em` })}
    display: block;
    position: absolute;
    right: -1.5em;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.blue400};
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    mask-image: url(${arrow.url});
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    mask-position: center;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    mask-size: cover;
  }
  :hover {
    color: ${({ theme }) => theme.colors.basic600};
    :after {
      background-color: ${({ theme }) => theme.link.hover};
    }
  }
`;
export const AnchorArrowStyle = css`
  a {
    ${ArrowStyle};
  }
`;
