import { heading3Style } from 'components/Common/Text';
import {
  CtaButton,
  ButtonArrow,
  Title,
} from 'components/SquareImageSlider/SquareImageSlider.styles';
import { css } from 'styled-components';
import { LadderThemeProp } from 'theme';

export const LadderSquareImageSlider = css`
  ${Title} {
    ${heading3Style}
  }

  ${CtaButton} {
    border-radius: 35px;
    background-color: unset;
    color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
    border-width: 1px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    ${ButtonArrow} {
      background-color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
    }
  }
`;
