import styled from 'styled-components';

export const VariantGroupTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 8px;
`;

export const VariantSwatchSelectionText = styled.div`
  width: 150px;
  margin-top: 0.25rem;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
`;

export const VariantSwatch = styled.div<{
  color?: string;
  isActive: boolean;
}>`
  outline: none;
  width: 37px;
  height: 37px;
  cursor: pointer;
  border: 3px solid white;
  ${({ color, isActive, theme }) => {
    return `${
      color
        ? `background:${color};
        box-sizing: border-box;
        `
        : `display: flex;
          border: 2px solid silver;
          align-items: center;
          justify-content: center;
        `
    }
    ${
      isActive
        ? `
        padding: 0;
        border: 1px solid #2040c8;
        box-shadow: inset 0 0 0 2px ${theme.colors.basic100};`
        : ''
    }
    border-radius: ${color ? '2rem;' : '6px;'}`;
  }};
`;

export const VariantSwatchContainer = styled.div`
  display: grid;
  grid-auto-columns: 40px;
  column-gap: 12px;
  grid-auto-flow: column;
  margin-bottom: 0.25rem;
  justify-items: stretch;
`;
