import styled from 'styled-components';
import {
  color,
  fontSize,
  textAlign,
  background,
  layout,
  right,
  bottom,
} from 'styled-utils';
import playArrow from 'svg/play-arrow.svg';
import closeButton from 'svg/modal-close.svg';
import { Button } from 'components/Common/Button';
import { IframeHTMLAttributes } from 'react';

interface CtaVideoProps extends IframeHTMLAttributes<any> {
  visible?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${background};
  overflow: hidden;
  width: 100%;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 0;
  padding-bottom: 56.25%;
`;

export const CtaVideo = styled.iframe<CtaVideoProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SquareImageContainer = styled.div`
  display: flex;
`;

export const CtaButton = styled(Button)`
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-align: center;
  border-radius: 35px;
  font-size: 20px;
  margin-top: 1rem;
  padding: 12px 35px 12px;
  text-decoration: none;
`;

export const ButtonArrow = styled.div`
  flex: 1;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  mask-image: url(${playArrow.url});
  background-color: ${({ theme }) => theme.colors.blue400};
  background-size: cover;
  width: 13px;
  height: 15px;
  margin-right: 10px;
`;

export const Title = styled.h2`
  ${color};
  ${textAlign({ textAlign: ['left', 'left'] })};
  ${fontSize({ fontSize: ['26px', '32px'] })};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  sup {
    font-weight: ${({ theme }) => theme.fontWeights.black};
  }
  text-align: center;
  margin: 40px 0 0;
  padding: 0 16px;
`;

export const SubtitleContainer = styled.div`
  margin: 0;
  padding: 0 19px;
  max-width: 739px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  background-image: url(${closeButton.url});
  background-size: cover;
  ${layout({
    width: [15, 15, 24],
    height: [15, 15, 24],
  })};
  position: relative;
  ${bottom({ bottom: [15, 15, 24] })};
  ${right({ right: [15, 15, 24] })};
`;

export const ModalInnerContainer = styled.div`
  width: 100%;
  max-width: 980px;
  @media (orientation: landscape) {
    padding: 30px 115px;
  }
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;
