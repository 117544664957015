import React from 'react';
import * as S from './styles';
import { TLengthStyledSystem } from 'styled-system';
import { Property } from 'csstype';
import { background, color, SpaceProps } from 'styled-utils';
import { PlacementStyled } from './styles';

export type ScreenAware<T = string> = {
  mobile?: T;
  desktop?: T;
};

export type ScreenAwareColorProps = {
  colorMobile?: string;
  colorDesktop?: string;
};

export const screenAwareColor = (
  mobileDefault: string,
  desktopDefault: string
) => ({
  colorMobile = mobileDefault,
  colorDesktop = desktopDefault,
}: ScreenAwareColorProps) =>
  color({ color: [colorMobile, colorMobile, colorDesktop] });

export type ScreenAwareBackgroundProps = {
  backgroundMobile?: string;
  backgroundDesktop?: string;
};
export const screenAwareBackground = (
  defaultMobile: string,
  defaultDesktop: string
) => ({
  backgroundMobile = defaultMobile,
  backgroundDesktop = defaultDesktop,
}: ScreenAwareBackgroundProps) =>
  background({
    background: [backgroundMobile, backgroundMobile, backgroundDesktop],
  });

export enum HorizontalPlacement {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  SPREAD = 'spread',
}

export enum VerticalPlacement {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
  SPREAD = 'spread',
}

export interface ContentPlacementData {
  vertical: {
    mobile: VerticalPlacement;
    desktop: VerticalPlacement;
  };
  horizontal: {
    mobile: HorizontalPlacement;
    desktop: HorizontalPlacement;
  };
}

interface ContentPlacementProps {
  contentPlacement: ContentPlacementData;
  height?: ScreenAware<Property.Height<TLengthStyledSystem>>;
  space?: SpaceProps;
  children: React.ReactNode;
}

export const horizontalMap = {
  [HorizontalPlacement.LEFT]: 'flex-start',
  [HorizontalPlacement.CENTER]: 'center',
  [HorizontalPlacement.RIGHT]: 'flex-end',
  [HorizontalPlacement.SPREAD]: 'space-between',
};

export const verticalMap = {
  [VerticalPlacement.TOP]: 'flex-start',
  [VerticalPlacement.MIDDLE]: 'center',
  [VerticalPlacement.BOTTOM]: 'flex-end',
  [VerticalPlacement.SPREAD]: 'space-between',
};

const ContentPlacement = ({
  contentPlacement,
  height,
  space,
  children,
}: ContentPlacementProps): React.ReactElement => (
  <S.Content
    justifyContent={[
      verticalMap[contentPlacement.vertical.mobile],
      verticalMap[contentPlacement.vertical.desktop],
    ]}
    alignItems={[
      horizontalMap[contentPlacement.horizontal.mobile],
      horizontalMap[contentPlacement.horizontal.desktop],
    ]}
    height={[height?.mobile || null, height?.desktop || null]}
    {...space}
  >
    {children}
  </S.Content>
);

export const Placement: React.FC<{
  tabletSameAsDesktop?: boolean;
  contentPlacement: ContentPlacementData;
}> = ({ contentPlacement, tabletSameAsDesktop = false, children }) => (
  <PlacementStyled
    justifyContent={[
      verticalMap[contentPlacement.vertical.mobile],
      verticalMap[
        tabletSameAsDesktop
          ? contentPlacement.vertical.desktop
          : contentPlacement.vertical.mobile
      ],
      verticalMap[contentPlacement.vertical.desktop],
    ]}
    alignItems={[
      horizontalMap[contentPlacement.horizontal.mobile],
      horizontalMap[
        tabletSameAsDesktop
          ? contentPlacement.horizontal.desktop
          : contentPlacement.horizontal.mobile
      ],
      horizontalMap[contentPlacement.horizontal.desktop],
    ]}
  >
    {children}
  </PlacementStyled>
);

interface Props {
  children: React.ReactNode;
  placement: ScreenAware<HorizontalPlacement>;
  tabIndex?: number;
}

export const Horizontal = ({ placement, children }: Props) => (
  <S.Horizontal
    alignItems={[
      horizontalMap[placement.mobile || 'center'],
      horizontalMap[placement.desktop || 'center'],
    ]}
  >
    {children}
  </S.Horizontal>
);

export default ContentPlacement;
