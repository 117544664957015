import styled from 'styled-components';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { device } from 'theme';
import {
  space,
  width,
  flexbox,
  layout,
  background,
  typography,
  position,
} from 'styled-utils';
import { AnchorArrowStyle } from 'components/Pdp';

export const Wrapper = styled.div<{ bgColor?: string }>`
  width: 100%;
  background-color: ${(props) => props.bgColor || props.theme.colors.basic200};
  position: relative;
`;

export const BgWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  ${position({ top: [195, 195, 0] })};
  z-index: 0;
  ${layout({ width: ['100%', '100%', '50%'], height: [300, 300, 580] })};
`;

export const Bg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

export const Top = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${flexbox({
    justifyContent: ['flex-start', 'flex-start', 'center'],
    alignItems: ['center', 'center', 'flex-start'],
  })};
  ${layout({ width: ['100%', '100%', '50%'], minHeight: [455, 455, 480] })};
  ${space({ pl: [0, 0, '8.6%'], pr: [0, 0, '1%'], py: [44, 44, 0] })};
  ${typography({ textAlign: ['center', 'center', 'left'] })};
`;

export const Title = styled.h2`
  font-weight: 900;
  font-size: 32px;
  line-height: 1.12;
  color: ${({ theme }) => theme.colors.basic800};
  ${space({ mb: ['10px', '10px', '20px'] })};
`;

export const Description = styled.div`
  ${space({ mx: [37, 37, 0] })};
  ${layout({ maxWidth: ['auto', 'auto', 420] })};
  font-size: 18px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.basic800};
  p {
    padding: 0;
    margin: 0;
  }
`;

export const Bottom = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  ${space({ px: [16, 16, 40], py: [20, 20, 40] })};
`;

export const BottomContent = styled.div`
  box-shadow: 0px 2px 54px rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.colors.basic100};
  ${background};
  ${space({ px: [0, 0], py: [16, 24] })};
`;

export const BottomItems = styled.div`
  display: flex;
  justify-content: space-between;
  ${flexbox({ flexWrap: ['wrap', 'nowrap'] })};
  ${space({ px: ['4px', '12px'], pt: ['4px', 0] })};
`;

export const BottomItem = styled.div`
  flex: 1;
  box-sizing: border-box;
  ${space({ px: ['12px', '12px'], py: ['12px', 0] })};
  ${flexbox({ flex: [0, 1], flexBasis: ['50%', 0] })};
`;

export const BottomItemTitle = styled.h3`
  display: inline-block;
  font-size: 20px;
  ${typography({ fontSize: [20, 32], lineHeight: ['24px', '36px'] })};
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0px;
  ${space({ m: '0 0 4px 0' })};
  color: ${(props) => props.color || props.theme.colors.basic800};
  text-transform: uppercase;
`;

export const BottomItemSubtitle = styled.h4`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0px;
  @media (min-width: ${device.small.width}) {
    text-transform: uppercase;
  }
  ${space({ m: ['0 0 4px 0', '0 0 10px 0'] })};
`;

export const BottomItemText = styled.div`
  p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.basic600};
  }
`;

export const Divider = styled.div`
  height: 1px;
  ${width({ width: ['calc(100% - 32px)', 'calc(100% - 48px)'] })};
  ${space({ mx: [16, 24], mt: [18, 24], mb: [30, 24] })};
  background: ${({ theme }) => theme.colors.basic400};
`;

export const BottomInfo = styled.div`
  display: flex;
  ${flexbox({ flexDirection: ['column', 'row'] })};
  ${space({ px: [30, 24] })};
`;
export const BottomInfoImageHolder = styled.div`
  padding: 0 16px;
  ${space({ px: [0, 16], mr: [0, 16], mb: [24, 0] })};
  ${layout({ width: ['100%', 'auto'] })};
  ${flexbox({
    flexBasis: ['60px', '280px'],
    alignItems: ['center', 'flex-start'],
  })};
  display: flex;
  justify-content: center;
`;

export const BottomInfoImage = styled(GatsbyImage)`
  ${layout({
    maxWidth: ['60%', '100%'],
    height: ['100%', 'auto'],
  })};
  max-height: 100%;
  width: 100%;
`;

export const BottomInfoText = styled.div`
  ${space({ mb: [16, 0] })};
  h1,
  h2,
  h3 {
    text-align: left;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    ${space({ px: [0, 0], py: ['8px', '4px'] })};
    margin: 0;
  }
  ${AnchorArrowStyle};
  a {
    margin: 0;
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black};
    :hover {
      color: ${({ theme }) => theme.colors.basic600};
    }
  }
`;
