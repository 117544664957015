import styled from 'styled-components';
import { space, layout, typography } from 'styled-utils';
import { ArrowHolder } from 'components/Slider/SliderArrows';

export const ProductSliderWrapper = styled.div`
  background: ${({ theme }) => theme.colors.basic100};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${space({ py: [32, 32, 60] })};
`;

export const Title = styled.h2`
  font-weight: 900;
  line-height: 36px;
  text-align: center;
  ${typography({ fontSize: [26, 26, 32] })};
  margin: 0;
  ${space({ mb: [24, 24, 32] })};
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  .keen-slider {
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;

    &__slide {
      position: relative;
      overflow: hidden;
      min-height: 100%;

      /* Prevent tooltip cutoff */
      :hover {
        overflow: visible;
      }
    }

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  ${space({ mt: [32, 32, 0] })};
`;

export const Dot = styled.button<{ isActive?: boolean }>`
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: 0;
  }
  border-radius: 50%;
  background: ${({ isActive, theme }): string =>
    isActive ? theme.colors.blue400 : theme.colors.basic400};
  ${layout({ size: '8px' })};
  ${space({ mx: '4px' })};
`;

export const FullProductSliderWrapper = styled.div<{
  showArrows: boolean;
}>`
  background: ${({ theme }) => theme.colors.basic100};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-width: 1330px;
  margin: ${({ showArrows }) => (showArrows ? '' : 0)} auto auto;
`;

export const FullSliderContainer = styled(SliderContainer)`
  ${space({ mx: [0, 0, '10px'] })};

  .keen-slider {
    overflow: visible;

    &__slide {
      overflow: visible;
    }
  }

  ${Dot} {
    ${layout({ size: ['8px', '8px', '12px'] })};
    ${space({ mx: ['4px', '4px', '8px'] })};
  }

  ${DotsContainer} {
    ${space({ mt: [32, 32, 50] })};
  }

  ${ArrowHolder} {
    margin-bottom: 80px;
  }
`;

export const SliderContentHoverShadowFix = styled.div`
  ${space({
    px: [0, 0, '10px'],
    m: ['0 -10px 0 -10px', '0 -10px 0 -10px', '0 -10px'],
  })};
  overflow: hidden;
`;

export const TileHoverShadowFix = styled.div`
  ${space({
    m: ['0 10px', '0 10px', '0 -11px'],
    p: ['', 0, '20px 22px'],
  })}
`;
