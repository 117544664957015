import styled, { css } from 'styled-components';
import {
  fontSize,
  color,
  lineHeight,
  textAlign,
  maxWidth,
  space,
  display,
  ColorProps,
  SpaceProps,
  MaxWidthProps,
  DisplayProps,
  typography,
} from 'styled-utils';

import { ParagraphProps } from './model';

export const h1Style = css`
  color: ${({ theme }) => theme.colors.basic800};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${lineHeight({ lineHeight: ['1.23', '1.33'] })};
  ${fontSize({ fontSize: ['26px', '30px', '36px'] })};
  ${space({ pb: ['16px', '31px'] })};
`;

export const H1 = styled.h1<DisplayProps & ColorProps>`
  ${h1Style};
  ${display};
  ${color};
`;

export const h2Style = css`
  color: ${({ theme }) => theme.colors.basic800};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${textAlign({ textAlign: ['center', 'left'] })};
  ${lineHeight({ lineHeight: ['1.11', '1.33'] })};
  ${fontSize({ fontSize: ['18px', '26px', '36px'] })};
`;

export const H2 = styled.h2<DisplayProps & SpaceProps & ColorProps>`
  ${h2Style};
  ${display};
  ${color};
  ${space};
`;

export const SectionHeader = styled(H1).attrs((): { as: string } => ({
  as: 'h2',
}))`
  ${space({ pb: ['30px', '40px'] })};
`;

export const h3Style = css`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  ${lineHeight({ lineHeight: ['1.29', '1.33'] })};
  ${fontSize({ fontSize: ['14px', '14px', '18px'] })};
`;

export const H3 = styled.h3<
  ColorProps & SpaceProps & MaxWidthProps & DisplayProps
>`
  ${h3Style};
  ${color};
  ${maxWidth};
  ${display};
  ${space};
`;

export const pStyle = css`
  ${space({ my: ['12px', '18px'] })};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  ${lineHeight({ lineHeight: ['20px', '24px'] })};
  ${fontSize({ fontSize: ['14px', '14px', '18px'] })};
`;

export const P = styled.p<ParagraphProps>`
  ${pStyle};
  ${color};
  ${display};

  ${(props) =>
    props.small &&
    css`
      font-size: 12px;
      line-height: 1.5;
    `};

  ${(props) =>
    props.bold &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    `};

  ${(props) =>
    props.semiBold &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    `};
  ${space};
`;

export const standardTagMixin = css`
  b {
    font-weight: 800;
  }

  em,
  i {
    font-style: italic;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  sub,
  sup {
    font-size: 75%;
    vertical-align: baseline;
    position: relative;
    top: 0.5em;
  }

  sup {
    top: -0.5em;
  }
`;

/**
 * New Typography styles
 */
export const heading1Style = css`
  font-family: ${({ theme }) => theme.fonts.alternate};
  ${({ theme }) => typography(theme.typography.heading1)};
  padding-bottom: unset;
`;

export const heading2Style = css`
  font-family: ${({ theme }) => theme.fonts.alternate};
  ${({ theme }) => typography(theme.typography.heading2)};
`;

export const heading3Style = css`
  font-family: ${({ theme }) => theme.fonts.alternate};
  ${({ theme }) => typography(theme.typography.heading3)};
`;

export const heading4Style = css`
  font-family: ${({ theme }) => theme.fonts.alternate};
  ${({ theme }) => typography(theme.typography.heading4)};
`;

// Also known as Secondary in Figma pattern library.
export const smallStyle = css`
  font-family: ${({ theme }) => theme.fonts.default};
  ${({ theme }) => typography(theme.typography.small)};
`;

// Also known as Tertiary in Figma pattern library.
export const xSmallStyle = css`
  font-family: ${({ theme }) => theme.fonts.default};
  ${({ theme }) => typography(theme.typography.xSmall)};
`;

export const bodyStyle = css`
  font-family: ${({ theme }) => theme.fonts.default};
  ${({ theme }) => typography(theme.typography.default)};
`;

// Generally used for buttons
export const labelStyle = css`
  ${bodyStyle};
  font-weight: 600;
`;
