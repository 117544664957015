import { Svg, SvgProps } from 'components/Common/Svg/Svg';
import styled from 'styled-components';
import {
  width,
  fontSize,
  space,
  textAlign,
  layout,
  LayoutProps,
} from 'styled-utils';

export const Container = styled.div`
  ${space({ my: ['16px', '24px'] })};
`;

export const Headline = styled.div`
  ${space({ mt: ['16px', '24px'] })};
  ${({ theme }) =>
    fontSize({
      fontSize: [theme.fontSizes.small, theme.fontSizes.default],
    })};
  ${textAlign({ textAlign: ['left', 'center'] })};
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  line-height: 32px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Section = styled.div`
  margin-bottom: 8px;
  ${width({ width: [1, '50%'] })};
`;

export const SectionItem = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const SectionItemIconWrapper = styled.div`
  width: 32px;

  svg {
    fill: currentColor;
    stroke: currentColor;
  }
`;

export const SectionItemIcon = styled(Svg)<SvgProps & LayoutProps>`
  ${layout};
`;

export const SectionItemText = styled.div`
  flex: 1;
  p,
  li {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 20px;
  }

  .textSmall {
    font-size: ${({ theme }) => theme.fontSizes.xSmall};
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.black};
  }

  p {
    margin-top: 0;
    margin-bottom: 4px;
  }

  ul {
    margin-bottom: 8px;
    padding: 0;
  }
`;
