import styled from 'styled-components';
import {
  background,
  fontSize,
  FontSizeProps,
  width,
  BackgroundProps,
  color,
  space,
  ColorProps,
} from 'styled-utils';
import { GatsbyImage } from '../GatsbyImage';

export const Container = styled.div<BackgroundProps>`
  display: flex;
  flex-direction: column;
  ${background};
`;

export const TitleContainer = styled.header<ColorProps>`
  ${space({ pt: ['56px'], pb: ['20px', '56px'] })};
  text-align: center;
`;

export const Title = styled.h2<FontSizeProps & ColorProps>`
  ${color};
  ${fontSize};
  text-align: center;
`;

type iconListProps = {
  layout?: string;
};

export const IconsListContainer = styled.div<iconListProps>`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: ${(props) =>
    props.layout === 'horizontal' ? 'row' : 'column'};
  align-items: ${(props) =>
    props.layout === 'horizontal' ? 'baseline' : 'center'};
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconPosition = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icon = styled(GatsbyImage)`
  width: 100%;
  align-self: center;
  img {
    width: 100%;
    margin: auto;
  }
`;

export const Info = styled.div`
  ${space({ pt: ['26px'], pb: ['50px'] })};
  ${width({ width: ['150px', '200px'] })};
  ${fontSize({ fontSize: ['16px', '20px'] })};
  text-align: center;
`;
export const InfoText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 20px;
  line-height: 24px;
`;
