import {
  HorizontalPlacement,
  VerticalPlacement,
} from 'components/Common/Placement/Placement';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  SanityImage,
  StandardBigImageFragment,
  StandardMediumImageFragment,
} from 'graphql-types';

export const config = {
  projectId: process.env.GATSBY_SANITY_PROJECT || 'dm4o0ui7',
  dataset: process.env.GATSBY_SANITY_DATASET || 'staging',
};

export const getSimplifiedImageData = (
  image?: SanityImage | StandardMediumImageFragment | StandardBigImageFragment
): IGatsbyImageData | undefined => image?.asset?.gatsbyImageData;

export enum Vertical {
  TOP = 'Top',
  MIDDLE = 'Center',
  SPREAD = 'Top/Bottom',
  BOTTOM = 'Bottom',
}

export enum Horizontal {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right',
  DEFAULT = 'Default',
}

export const mapVertical = (position: Vertical): VerticalPlacement => {
  return (
    {
      [Vertical.TOP]: VerticalPlacement.TOP,
      [Vertical.MIDDLE]: VerticalPlacement.MIDDLE,
      [Vertical.SPREAD]: VerticalPlacement.SPREAD,
      [Vertical.BOTTOM]: VerticalPlacement.BOTTOM,
    }[position] || VerticalPlacement.SPREAD
  );
};

export const mapHorizontal = (position: Horizontal): HorizontalPlacement => {
  return (
    {
      [Horizontal.LEFT]: HorizontalPlacement.LEFT,
      [Horizontal.CENTER]: HorizontalPlacement.CENTER,
      [Horizontal.RIGHT]: HorizontalPlacement.RIGHT,
      [Horizontal.DEFAULT]: HorizontalPlacement.LEFT,
    }[position] || HorizontalPlacement.LEFT
  );
};
