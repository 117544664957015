import styled from 'styled-components';
import { display, fontSize, fontWeight, margin, maxWidth } from 'styled-utils';
import { AnchorArrowStyle } from 'components/Pdp';

export const Bookmark = styled.a`
  position: relative;
  top: -160px;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.basic200};
  padding: 2em 1em 2em 1em;
`;

export const Splitter = styled.div<{ hideDesktop?: boolean }>`
  ${(props) =>
    props.hideDesktop ? display({ display: ['block', 'block', 'none'] }) : ''};
  border: 1px solid ${({ theme }) => theme.colors.basic400};
  box-sizing: border-box;
  margin: 1.5em 0 2em 0;
`;

export const FeaturedReviewsContainer = styled.div`
  ${display({ display: ['block', 'block', 'flex'] })};
  ${maxWidth({ maxWidth: ['auto', 'auto', '64em'] })};
  margin: auto;
`;

export const FeaturedReview = styled.div<{ addMargin: boolean }>`
  ${(props) =>
    props.addMargin
      ? margin({
          marginLeft: [0, 0, '2.7em'],
          marginTop: ['3em', '3em', '0'],
        })
      : ''};
`;

// #region See All Reviews

export const SeeAllReviewsContainer = styled.div`
  ${margin({ marginTop: ['2em', '3em'], marginBottom: ['0em', '1em'] })};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${AnchorArrowStyle};
`;

export const SeeAllReviews = styled.a`
  cursor: pointer;
  text-align: center;
  ${({ theme }) =>
    fontWeight({
      fontWeight: [theme.fontWeights.regular, theme.fontWeights.black],
    })};
  ${fontSize({ fontSize: [12, 18] })};
  text-decoration: none;
`;

export const SeeAllReviewsSplitter = styled(Splitter)`
  margin-top: 3em;
`;
// #endregion

export const ReviewsWidgetContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.basic200};
  max-width: 1280px;
  margin: 0 auto;
`;
