import styled from 'styled-components';
import { space } from 'styled-utils';

export const SubscribeAndSaveContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const SubscribeAndSaveCheckboxContainer = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  align-items: center;
  padding: 0.2rem;
  min-height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.basic400};
  ${space({ pr: ['7px', '7px', '10px'], py: ['14px', '7px', '7px'] })};
`;

export const SubscribeAndSaveDetails = styled.label`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.small};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SavingsLabel = styled.div`
  border-radius: 4px;
  text-align: center;
  line-height: 20px;
  padding: 1px 8px;
  background-color: ${({ theme }) => theme.priceViolator.background};
`;

export const SwatchTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const SwatchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:not(:last-child) {
    margin-right: 8px;
  }
`;
