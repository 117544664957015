import styled, { css } from 'styled-components';
import { color, ColorProps, TextAlignProps } from 'styled-utils';
import {
  h1Style,
  h2Style,
  h3Style,
  heading1Style,
  heading2Style,
  heading3Style,
  heading4Style,
  pStyle,
  smallStyle,
  standardTagMixin,
  xSmallStyle,
} from 'components/Common/Text';
import { textAlign } from 'styled-system';

export const A = styled.a<ColorProps>`
  ${color};
  text-decoration: none;
  cursor: pointer;

  ${({ color }) =>
    color
      ? ''
      : css`
          color: ${({ theme }) => theme.link.color};

          &:hover {
            color: ${({ theme }) => theme.link.hover};
          }
        `};
`;

// Below TODO's should be done once openfit can be updated.
// TODO: Replace h1Style-h3Style with heading1-heading3
// TODO: Replace p with { margin-top: 1em; margin-bottom: 1em; }
export const SanityRichTextStyles = styled.div`
  ${standardTagMixin};

  h1 {
    ${h1Style};
  }

  h2 {
    ${h2Style};
  }

  h3 {
    ${h3Style};
  }

  h4,
  p.heading4 {
    ${heading4Style};
  }

  p.heading1 {
    ${heading1Style};
  }

  p.heading2 {
    ${heading2Style};
  }

  p.heading3 {
    ${heading3Style};
  }

  p.heading4 {
    ${heading4Style};
  }

  p {
    ${pStyle};
  }

  ul {
    ${pStyle};
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 1.15em;
    list-style-type: disc;
  }

  .textSmall {
    ${smallStyle};
  }

  .textXSmall {
    ${xSmallStyle};
  }
`;

export const TextAlign = styled.span<TextAlignProps>`
  ${textAlign};
  display: block;
`;
