import React, { FC } from 'react';
import styled from 'styled-components';
import { display } from 'styled-utils';
import ArrowSVG from '../LadderHomeSlider/arrow.svg';

const SliderArrowsStyle = styled.div`
  width: 100%;
  display: flex;
`;

export const ArrowHolder = styled.div<{ right?: boolean }>`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 80px;
`;

export const DesktopOnlyArrow = styled(SliderArrowsStyle)`
  ${ArrowHolder} {
    ${display({ display: ['none', 'none', 'flex'] })};
  }
`;

const Arrow = styled.button<{ right?: boolean }>`
  border: none;
  background-image: url('${ArrowSVG.url}');
  width: 28px;
  height: 55px;
  background-color: transparent;
  cursor: pointer;
  ${({ right }) => right && 'transform: rotate(180deg);'};
`;

export const SliderArrows: FC<{
  slider: { next: () => void; prev: () => void };
}> = ({ slider, children }) => (
  <SliderArrowsStyle>
    <ArrowHolder>
      <Arrow onClick={() => slider.prev()} />
    </ArrowHolder>
    {children}
    <ArrowHolder right>
      <Arrow right onClick={() => slider.next()} />
    </ArrowHolder>
  </SliderArrowsStyle>
);
