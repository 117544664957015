import { SanityRichTextStyles } from 'components/Common/SanityRichText/SanityRichText.styles';
import {
  heading1Style,
  heading2Style,
  heading3Style,
} from 'components/Common/Text';
import { CommonTitle } from 'components/Pdp/Typography.styles';
import { css } from 'styled-components';

export const LadderTypographyStyles = css`
  h1 {
    ${heading1Style};
    text-transform: uppercase;
  }

  p.heading1 {
    text-transform: uppercase;
  }

  h2,
  p.heading2 {
    ${heading2Style};
    text-transform: uppercase;
  }

  p.heading2 {
    text-transform: uppercase;
  }

  ${CommonTitle},
  h3 {
    ${heading3Style};
    text-transform: uppercase;
  }
  p.heading3 {
    text-transform: uppercase;
  }

  h4,
  p.heading4 {
    text-transform: uppercase;
  }

  b {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  ${SanityRichTextStyles} {
    p,
    ul {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
`;
