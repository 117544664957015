import styled from 'styled-components';
import { fontSize } from 'styled-utils';

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.basic800};
  ${fontSize({ fontSize: ['26px', '32px'] })};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: inherit;
  font-weight: ${({ theme }) => theme.fontWeights.black};
`;

export const Description = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: inherit;
  ${fontSize({ fontSize: ['14px', '18px'] })};
`;
