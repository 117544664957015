import { AnchorArrowStyle } from 'components/Pdp';
import styled from 'styled-components';
import { display, space, width } from 'styled-utils';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { pStyle } from 'components/Common/Text';
import { Svg } from 'components/Common/Svg';

export const BundleItemListContainer = styled.div`
  ${display({ display: ['block', 'grid'] })};
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-between;
  @media (max-width: 1280px) {
    justify-content: space-around;
  }
  flex-wrap: wrap;
`;

export const BundleItem = styled.div`
  padding: 1.25rem;
  ${width({ width: ['auto', '300px'] })};
`;

export const Image = styled(GatsbyImage)`
  margin-bottom: 1rem;
  height: 178px;
  width: 100%;
`;

export const IngredientsButtonWrapper = styled.div`
  ${AnchorArrowStyle};
`;

export const IngredientsButton = styled.a`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fontSizes.small};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.basic600};
  }
`;
export const NsfCerfButton = styled.a`
  color: ${({ theme }) => theme.colors.basic800};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fontSizes.small};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.basic600};
  }
`;
export const Spacer = styled.div`
  margin: 15px;
`;
export const BundleItemImageContainer = styled.div`
  position: relative;
`;
export const NsfCertOverlay = styled(Svg)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const ValueOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  background-color: ${({ theme }) => theme.priceViolator.background};
`;

export const ValueOverlay = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  line-height: 20px;
  ${space({ mx: '.75rem' })};
  justify-content: center;
  align-content: center;
`;

export const BundleItemDescription = styled.div`
  h2 {
    font-weight: ${({ theme }) => theme.fontWeights.black};
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0 0;
    text-align: center;
  }

  p {
    ${pStyle};
  }

  p,
  a {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 20px;
  }
`;
