import React from 'react';
import Helmet from 'react-helmet';
import { LadderGlobalStyle, LadderStyle } from 'templates/ladder/Ladder.styles';

export const LadderApp: React.FC = ({ children }) => {
  return (
    <LadderStyle>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="qqudbufukj8tkpdk2ndfzuelyta2ai"
        />
      </Helmet>
      <LadderGlobalStyle />
      {children}
    </LadderStyle>
  );
};
