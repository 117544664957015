import React from 'react';

import {
  Overlay,
  ModalStyled,
  InnerContent,
  ContentRelative,
  StyledModalClose,
} from 'components/Modal/Modal.styles';

export type ModalProps = {
  showBtnClose?: boolean;
  showBtnCloseMobile?: boolean;
  showBtnCloseDesktop?: boolean;
  onBtnCloseClick?: React.MouseEventHandler;
  onOverlayClick?: React.MouseEventHandler;
};

export const Modal: React.FC<ModalProps> = ({
  onOverlayClick,
  onBtnCloseClick,
  showBtnClose,
  showBtnCloseMobile,
  showBtnCloseDesktop,
  children,
}) => (
  <Overlay>
    <ModalStyled onClick={onOverlayClick}>
      <ContentRelative>
        <StyledModalClose
          role="button"
          tabIndex={0}
          onClick={onBtnCloseClick}
          {...{ showBtnClose, showBtnCloseMobile, showBtnCloseDesktop }}
        />
        <InnerContent
          onClick={(e) => e.stopPropagation()}
          borderRadius={'10px'}
        >
          {children}
        </InnerContent>
      </ContentRelative>
    </ModalStyled>
  </Overlay>
);
