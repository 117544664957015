import styled from 'styled-components';
import {
  alignItems,
  AlignItemsProps,
  FlexDirectionProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  space,
  SpaceProps,
} from 'styled-utils';

export const PlacementStyled = styled.div<
  AlignItemsProps & JustifyContentProps
>`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  ${alignItems};
  ${justifyContent};
`;

export const Content = styled.div<
  JustifyContentProps &
    FlexDirectionProps &
    AlignItemsProps &
    SpaceProps &
    HeightProps
>`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  ${alignItems};
  ${justifyContent};
  ${height};
  ${space};
`;

export const Horizontal = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  ${alignItems};
`;
