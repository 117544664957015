import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  lineHeight,
  space,
  right,
  fontWeight,
} from 'styled-utils';
import { AnchorArrowStyle } from 'components/Pdp';
import { fontSize } from 'styled-system';

export const Container = styled.div<ColorProps>`
  ${color};
  ${space({ py: 40, px: [16, 32, 120] })};
`;

export const Title = styled.h2<ColorProps>`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${color};
  ${fontSize({ fontSize: [26, 32] })};
  line-height: 36px;
  ${space({ mx: 10, mt: 10 })};
`;

export const SubTitle = styled.p<ColorProps>`
  ${color};
  ${fontSize({ fontSize: [14, 18] })};
  text-align: center;
  ${lineHeight({ lineHeight: [`20px`, `24px`] })};
  ${space({ mt: 20, mb: [30, 40], mx: 10 })};
`;

export const QuestionContainer = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
`;

export const ToggleIconContainer = styled.div`
  transform: rotate(0);
  transition: all 300ms;
  ${right({ right: [10, 20] })};
`;

export const QuestionTitle = styled.div<ColorProps>`
  cursor: pointer;
  position: relative;
  ${color};
  ${space({ px: [15, 25], py: [18, 25] })};
  ${fontSize({ fontSize: [14, 18] })};
  display: flex;
  align-items: center;
`;

export const QuestionTitleText = styled.div`
  flex: 1;
`;

export const QuestionAnswerWrapper = styled.span<{ isOpen: boolean }>`
  max-height: 0px;
  opacity: 0;
  transition: all 300ms ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 1000px;
      opacity: 1;
    `};
`;

export const QuestionAnswer = styled.div<ColorProps>`
  ${color};
  ${space({ mx: [15, 25], mb: 30 })};
  ${AnchorArrowStyle};
  p,
  a {
    ${fontSize({ fontSize: [14, 18] })};
    ${lineHeight({ lineHeight: ['20px', '24px'] })};
  }
`;

export const BorderLine = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.basic400};
`;

export const FAQLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-top: 2px solid ${({ theme }) => theme.colors.basic400};
  justify-content: center;
  align-items: center;
  ${AnchorArrowStyle};
`;

export const FAQLink = styled.a<ColorProps>`
  cursor: pointer;
  text-align: center;
  margin-top: 30px;
  ${color};
  ${({ theme }) =>
    fontWeight({
      fontWeight: [theme.fontWeights.regular, theme.fontWeights.black],
    })};
  ${fontSize({ fontSize: [12, 14] })};
  text-decoration: none;
`;
