import { Button } from 'components/Common/Button';
import styled from 'styled-components';
import {
  display,
  width,
  top,
  right,
  position,
  fontSize,
  space,
  maxWidth,
} from 'styled-utils';
import { SquareImageWrapper } from '../ProductImage/ProductImage.styles';

export const Container = styled.div`
  position: relative;
  ${display({ display: ['block', 'flex'] })};
`;

export const SavingsContainer = styled.div`
  position: absolute;
  ${top({ top: ['16px', '30px'] })};
  ${right({ right: ['16px', '30px'] })};
`;

export const ProductImageContainer = styled.div`
  position: relative;
  ${width({ width: ['100%', '50%'] })};
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  ${space({ p: 0 })};

  ${SquareImageWrapper}, ${SavingsContainer} {
    ${space({ mt: ['90px', 0] })};
  }
`;

export const TitleContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${fontSize({ fontSize: ['20px', '32px'] })};
  ${position({ position: ['absolute', 'relative'] })};
  ${top({ top: [36, 0] })};
  ${maxWidth({ maxWidth: ['calc(100% - 150px)', '100%'] })};
`;

export const BuyBoxContainer = styled.div`
  ${width({ width: ['auto', '50%'] })};
  ${space({ p: ['12px 15px', '12px 15px', '0 40px'] })};
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const OptionsElement = styled.div<{ width?: string }>`
  margin-right: auto;
  margin-top: 1em;
  ${({ width }) => (width ? `width: ${width}` : '')};
`;

export const VariantOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:not(:last-child) {
    margin-right: 8px;
  }
`;

export const VariantTypeStyle = styled.div`
  min-width: 73px;
  min-height: 40px;
  text-align: center;
  padding-right: 4.5px;
  padding-left: 4.5px;
  line-height: 40px;
`;

export const BuyButton = styled(Button)`
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  ${({ theme }) =>
    fontSize({
      fontSize: [
        theme.fontSizes.default,
        theme.fontSizes.default,
        theme.fontSizes.large,
      ],
    })};
`;

export const Truck = styled.svg`
  width: 24px;
  height: 18px;
  margin-right: 1rem;
`;

export const Calendar = styled.svg<{
  hasFreeShipping?: boolean;
}>`
  width: 24px;
  height: 19px;
  margin-left: ${(props) =>
    props.hasFreeShipping
      ? `${space({ marginLeft: ['0', '0', '2rem'] })}`
      : '0'};
  margin-right: 1rem;
`;

export const ShippingInfo = styled.div`
  padding: 1rem 0;
  display: ${display({ display: ['block', 'block', 'flex'] })};
  margin-bottom: 1.5rem;
`;

export const ShippingInfoItem = styled.div`
  display: flex;
  margin-right: ${space({
    marginRight: ['0', '0', '2rem'],
    marginBottom: ['1rem', '1rem', 0],
  })};
`;

export const IngredientsButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.default};
  margin: auto;
`;

export const BuyButtonDivider = styled.span`
  border-color: ${({ theme }) => theme.colors.basic100};
  &:hover {
    border-color: ${({ theme }) => theme.colors.red400};
  }
  border-left: 1px solid;
  margin: 0 1rem;
`;

export const IngredientsModalContent = styled.div`
  ${space({ p: ['60px 15px', '60px 15px', '43px 125px'] })};
  width: 90vw;
  max-width: 792px;
`;

export const TabletDescriptionContainer = styled.div`
  padding: 0 40px;
`;
