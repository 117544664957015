import { css } from 'styled-components';
import { Title } from 'components/Product/ProductRating/ProductRating.styles';
import { heading3Style } from 'components/Common/Text';

export const LadderProductRatingTitle = css`
  ${Title} {
    ${heading3Style}
    text-transform: uppercase;
    margin-top: 2rem;
  }
`;
